import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.min.css";
import "assets/styles/docs.css";

import LoadingSpinner from './Svegile/Views/LoadingSpinner.js';

//Svegile
const SvegileHome = lazy(() => import('./Svegile/Views/Home.js'));
const Services = lazy(() => import('./Svegile/Views/Services.js'));
const Discover = lazy(() => import('./Svegile/Views/Discover.js'));
const Careers = lazy(() => import('./Svegile/Views/Careers.js'));
const ContactUs = lazy(() => import('./Svegile/Views/ContactUs.js'));
const AboutUs = lazy(() => import('./Svegile/Views/AboutUs.js'));
const Resource = lazy(() => import('./Svegile/Views/Resource.js'));
const Pilot = lazy(() => import('./Svegile/Views/Pilot.js'));
const Insights = lazy(() => import('./Svegile/Views/blogs/BlogPost.js'));
const PrivacyPolicy = lazy(() => import('./Svegile/Views/PrivacyPolicy.js'));

// Lazy-loaded service components
const SoftwareDevelopment = lazy(() => import('./Svegile/Views/services/SoftwareDevelopment.js'));
const WebDevelopment = lazy(() => import('./Svegile/Views/services/WebDevelopment.js'));
const MobileDevelopment = lazy(() => import('./Svegile/Views/services/MobileDevelopment.js'));
const QualityAssurance = lazy(() => import('./Svegile/Views/services/QualityAssurance.js'));
const EnterpriseDevelopment = lazy(() => import('./Svegile/Views/services/EnterpriseDevelopment.js'));
const DevOpsAndCloud = lazy(() => import('./Svegile/Views/services/DevOpsAndCloud.js'));
const StartUpDevelopment = lazy(() => import('./Svegile/Views/services/StartUpDevelopment.js'));
const DedicatedDevelopmentTeam = lazy(() => import('./Svegile/Views/services/DedicatedDevelopmentTeam.js'));

ReactDOM.render(
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route path="/home" exact component={SvegileHome} />
          <Route path="/services" exact component={Services} />
          <Route path="/discover" exact component={Discover} />
          <Route path="/careers" exact component={Careers} />
          <Route path="/contactUs" exact component={ContactUs} />
          <Route path="/aboutus" exact component={AboutUs} />
          <Route path="/resources" exact component={Resource} />
          <Route path="/pilot" exact component={Pilot} />
          <Route path="/insights" exact component={Insights} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />

          {/* Service Routes */}
          <Route path="/services/software-development" exact component={SoftwareDevelopment} />
          <Route path="/services/web-development" exact component={WebDevelopment} />
          <Route path="/services/mobile-development" exact component={MobileDevelopment} />
          <Route path="/services/quality-assurance" exact component={QualityAssurance} />
          <Route path="/services/enterprise-development" exact component={EnterpriseDevelopment} />
          <Route path="/services/devops-cloud" exact component={DevOpsAndCloud} />
          <Route path="/services/dedicated-dev-team" exact component={DedicatedDevelopmentTeam} />
          <Route path="/services/startup-development" exact component={StartUpDevelopment} />

          <Redirect from="*" to="/home" />
        </Switch>
      </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);
